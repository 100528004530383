<template>
  <section class="container">

    <div class="home-hero grid vcenter">
      <div class="half">
        <AMARLogo id="main-logo" />
      </div>
      
      <div class="half acenter">
        <h1 class="tagline" ref="title" data-speed="1.1">
          Creative <br />solutions <br />you will <span>love</span>
        </h1>
      </div>
    </div>

    <div class="grid disclaimer hide-mobile">
      <div class="full">
        <MicroScroll class="scroll-icon" />
        <p>We are a global remote studio specialised in Branding, Digital Design and JAMstack Development.</p>
      </div>
    </div>


  </section>
</template>

<script setup>
  import AMARLogo from '~/assets/svg/AMAR_Logo.svg?component'

  import { gsap } from 'gsap'
  import { SplitText } from 'gsap/SplitText'
  gsap.registerPlugin(SplitText)

  const title = ref()

  var tl = gsap.timeline()
  let mySplitText, chars

  const allDone = () => {
    tl.clear().time(0);
    mySplitText.revert();
  }

  onMounted(() => {
    
    mySplitText = new SplitText(title.value, { type: "words, chars" }),
    chars = mySplitText.chars; //an array of all the divs that wrap each character

    gsap.set(title.value, { perspective: 400 });

    if(title.value) {
      tl.from(chars, {
        duration: .6,
        opacity: 0,
        scale: 0,
        transformOrigin: "0% 0%",
        ease: "back",
        stagger: 0.02,
        onComplete: allDone
      });
    }
    
  });

</script>

<style lang="scss" scoped>
.home-hero {
  height: calc(100vh - 180px);

  #main-logo {
    fill: var(--color-white);
    filter: drop-shadow(0px 40px 50px rgba(var(--color-pure-black_rgb), 0.3));
  }

}
.disclaimer{
  height: 100px;
  text-transform: uppercase;
  text-align: left;
  
  p {
    font-size: 12px;
    max-width: 380px;
    margin-top: 10px;
  }
  .scroll-icon {
    float: left;
    margin-right: 20px;
  }
}

</style>

