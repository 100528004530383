<template>
	<div>
		<HomeHero />
		<LazyHomeIntro :intro="page?.data.intro" />
		<SliceZone wrapper="main" :slices="page?.data.slices ?? []" :components="components" />
		<LazyLatestInsights />
	</div>
</template>

<script setup>
import { components } from "~/slices";

const prismic = usePrismic();

const { data: page } = useAsyncData("[home]", () =>
	prismic.client.getSingle("home")
);

useSeoMeta({
	title: () => page.value?.data.meta_title,
	description: () => page.value?.data.meta_description,
	ogTitle: () => page.value?.data.meta_title,
	ogUrl: () => `https://amardesign.co.uk`,
	ogDescription: () => page.value?.data.meta_description,
	ogImageType: () => 'image/jpeg',
	ogImage: () => page.value?.data.meta_image.url,
	ogImageWidth: () => '1200',
	ogImageHeight: () => '627',
	ogType: () => 'website',
	twitterTitle: () => page.value?.data.meta_title,
	twitterImage: () => page.value?.data.meta_image.url,
	twitterDescription: () => page.value?.data.meta_description,
})
</script>